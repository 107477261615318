import { isFunction, omit } from 'lodash-es';
import { AnimatePresence, motion, Target, TargetAndTransition } from 'motion/react';
import type { InputProps, SearchFieldProps } from 'react-aria-components';
import { Button, Input, SearchField } from 'react-aria-components';
import { tv, VariantProps } from 'tailwind-variants';
import Icon, { IconName } from '../../atoms/icon/icon';
import { IconButton } from '../icon-button/icon-button';

type CSSVariables = {
  [key: `--${string}`]: string | number;
};

interface _SearchFieldProps extends SearchFieldProps {
  label?: string;
  iconName?: IconName;
  errorMessage?: string | string[];
  isDisabled?: boolean;
  inputClassName?: InputProps['className'];
  onStateChange?: (state: 'expanded' | 'collapsed') => void;
  isCollapsible?: boolean;
  state?: 'expanded' | 'collapsed';
}

const transition = {
  type: 'spring',
  bounce: 0,
};

const MotionInput = motion.create(Input);
const MotionSearchField = motion.create(SearchField);
const MotionButton = motion.create(Button);
const MotionIcon = motion.create(Icon);

const searchField = tv({
  base: 'group relative flex w-full items-center overflow-hidden rounded-full',
  slots: {
    close: 'absolute right-0 p-2 transition-opacity group-data-[empty]:!opacity-0',
    closeIcon: 'h-4 w-4',
    input: 'w-full truncate rounded-full border px-8 py-[11px] text-xs outline-none',
    icon: 'absolute left-2 z-10',
  },
  variants: {
    surface: {
      dark: {
        base: 'text-white',
        close: 'text-white',
        icon: 'text-white',
        input: [
          'bg-[rgb(255_255_255/var(--dark-surface-bg-opacity,1))]',
          'border-[rgb(var(--grey-200)/var(--border-opacity,1))]',
          'rac-focus:border-[rgb(255_255_255/var(--border-opacity,1))]',
          'placeholder:text-[rgb(var(--grey-500)/var(--placeholder-opacity,1))]',
          'text-[color:rgb(var(--grey-100)/var(--text-opacity,1))]',
        ],
      },
      light: {
        base: 'text-grey-900',
        close: 'text-grey-700',
        icon: 'text-grey-700',
        input: [
          'bg-[rgb(255_255_255/var(--light-surface-bg-opacity,1))]',
          'border-[rgb(var(--grey-400)/var(--border-opacity,1))]',
          'rac-focus:border-[rgb(var(--grey-600)/var(--border-opacity,1))]',
          'placeholder:text-[rgb(var(--grey-600)/var(--placeholder-opacity,1))]',
          'text-[color:rgb(var(--grey-900)/var(--text-opacity,1))]',
        ],
      },
    },
  },
  defaultVariants: {
    surface: 'light',
  },
});

function _SearchField({
  iconName = 'search',
  label = 'Search by name, email or phone',
  surface,
  errorMessage,
  isDisabled,
  className,
  inputClassName,
  state = 'expanded',
  isCollapsible,
  onStateChange,
  ...props
}: _SearchFieldProps & VariantProps<typeof searchField>) {
  const { base, close, icon, input, closeIcon } = searchField({ surface });

  const isExpanded = state === 'expanded';

  return (
    <AnimatePresence initial={false} mode="wait">
      {isExpanded ? (
        <MotionSearchField
          animate={{ width: 'auto', transition }}
          aria-label="Search"
          aria-labelledby="search"
          className={(bag) =>
            base({
              className: isFunction(className) ? className(bag) : className,
            })
          }
          data-testid="search-field"
          exit={{ width: 40, transition: { ...transition } }}
          initial={{ width: 40 }}
          isDisabled={isDisabled}
          isInvalid={!!errorMessage}
          key="parent"
          type="text"
          {...omit(props, 'style')}>
          <MotionIcon
            animate={{ width: 16, height: 16, transition }}
            className={icon()}
            exit={{ width: 24, height: 24, transition }}
            initial={{ width: 24, height: 24 }}
            key="icon"
            name={iconName}
          />
          <MotionInput
            animate={
              {
                '--placeholder-opacity': 1,
                '--border-opacity': 1,
                '--dark-surface-bg-opacity': 0.25,
                '--light-surface-bg-opacity': 1,
                '--text-opacity': 1,
                transition,
              } as TargetAndTransition & CSSVariables
            }
            aria-label="Search"
            aria-labelledby="search"
            className={(bag) =>
              input({
                className: isFunction(inputClassName) ? inputClassName(bag) : inputClassName,
              })
            }
            exit={
              {
                '--placeholder-opacity': 0,
                '--border-opacity': 0,
                '--dark-surface-bg-opacity': 0,
                '--light-surface-bg-opacity': 0,
                '--text-opacity': 0,
                transition,
              } as TargetAndTransition & CSSVariables
            }
            initial={
              {
                '--placeholder-opacity': 0,
                '--border-opacity': 0,
                '--dark-surface-bg-opacity': 0.5,
                '--light-surface-bg-opacity': 0,
                '--text-opacity': 0,
              } as Target & CSSVariables
            }
            key="child"
            layout
            onBlur={() => {
              if (isFunction(onStateChange) && isCollapsible) {
                onStateChange('collapsed');
              }
            }}
            placeholder={label}
          />
          <MotionButton
            animate={{ opacity: 1, transition }}
            className={close()}
            exit={{ opacity: 0, transition }}
            initial={{ opacity: 0 }}>
            <Icon className={closeIcon()} name="close" />
          </MotionButton>
        </MotionSearchField>
      ) : (
        <IconButton
          aria-label="Search"
          aria-labelledby="search"
          data-testid="search-field"
          icon={iconName}
          onPress={() => {
            if (isFunction(onStateChange) && isCollapsible) {
              onStateChange(isExpanded ? 'collapsed' : 'expanded');
            }
          }}
          size="md"
          variant={surface}
        />
      )}
    </AnimatePresence>
  );
}

export { _SearchField as SearchField };
export type { _SearchFieldProps as SearchFieldProps };
